<template>
  <div
      :class="{
        'lg:w-modal-picture lg:h-modal-picture justify-center': modal,
      }"
      class="relative flex flex-col items-center my-auto border border-grayed border-dotted rounded w-65 h-65 lg:w-79 lg:h-79 cursor-pointer hover:opacity-50" @click="$emit('clicked')">
    <div  class="rounded object-cover absolute top-3 lg:top-5">
      <base-icon name="union" color="fill-gray" :width="41" :height="41" :class="{'p-0': modal}">
      </base-icon>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";

export default {
  name: "AddPictureButton",
  components: {BaseIcon},
  props: {
    bigger: Boolean,
    modal: Boolean,
  },
  data() {
    return {
      isShowGalleryModal: false,

    }
  },
}
</script>
