<template>
  <div v-if="options !== null" class="flex flex-col gap-3">
    <BadgeFrame
      v-if="galleryData.length > 0 && (
        !userData.hasPictureFeatured || !userData.hasPictureFace || !userData.hasPictureShape
        || userData.picturesPending > 0 || userData.picturesRejected > 0
      )"
    >
      <Badge tagName="span" variant="badgeError" v-if="!userData.hasPictureFace">
        {{ trans('LABEL:FacePictureMissing') }}
      </Badge>
      <Badge tagName="span" variant="badgeError" v-if="userData.hasPictureFace && !userData.hasPictureFeatured">
        {{ trans('LABEL:FeaturedPictureNotSet') }}
      </Badge>
      <Badge tagName="span" variant="badgeError" v-if="!userData.hasPictureShape">
        {{ trans('LABEL:ShapePictureMissing') }}
      </Badge>
      <Badge tagName="span" variant="badgeInfo" v-if="userData.picturesPending > 0">
        {{ transChoice('LABEL:PicturesPendingCount', userData.picturesPending) }}
      </Badge>
      <Badge tagName="span" variant="badgeInfo" v-if="userData.picturesRejected > 0">
        {{ transChoice('LABEL:PicturesRejectedCount', userData.picturesRejected) }}
      </Badge>
    </BadgeFrame>
    <div>
      <VueFileAgent
        ref="fileUploader"
        v-model="picturesData"
        :meta="false"
        :maxFiles="configData.userGalleryLimit"
        :max-size="options.maxSize"
        :uploadUrl="options.url"
        :upload-headers="options.headers"
        :deletable="true"
        :thumbnail-size="424"
        @upload="onUpload($event)"
        @upload:error="onUpload($event)"
      >
	      <template v-slot:before-inner>
		      <div class="mt-6 mb-8" v-show="picturesLoadingCounter > 0 && picturesData.length > 0 && !disableUpperLoading">
			      <Loader no-border />
		      </div>
	      </template>
        <template v-slot:file-preview="{index, fileRecord}" >
          <div class="file-preview group">
            <span class="image-preview">
              <span class="file-preview-overlay animate-pulse bg-grayed rounded-lg overflow-hidden"></span>
                <span class="thumbnail rounded-lg border border-grayed"
                      style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden;">
                  <a @click="openLightBoxGallery(index)"
                     v-if="picturesData[index] && picturesData[index].hasOwnProperty('id')"
                  >
                    <div
                      class="relative flex w-full h-auto cursor-pointer"
                    >
                      <Picture
	                      loader-enabled
	                      :pictures-length="picturesData.length"
                        classes="w-modal-picture h-modal-picture
                        object-cover filter transition
                        group-hover:brightness-35 group-hover:grayscale group-hover:contrast-100 group-hover:filter"
                        :src="picturesData[index].squareUrl" alt="profile picture"
                        :class="[`${getPictureFilter(picturesData[index] ? picturesData[index].status.slug : '', true)}`]"
                      />
                      <!--      GRADIENT DIV WITH STATUS TEXT      -->
                      <div
                        v-if="picturesData[index] && picturesData[index].hasOwnProperty('status')"
                        class="w-full absolute bottom-0 left-0 uppercase box-gradient"
                        :class="[`${getPictureFilter(picturesData[index].status.slug, true)}`]"
                      >
                        <span v-if="picturesData[index].status.slug!=='a_general'"
                          class="absolute p-3 font-semibold text-s"
                          :class="{'text-dodgerBlue':picturesData[index].isFeatured||picturesData[index].status.slug==='a_face',
                            'text-grayed':picturesData[index].status.slug==='a_shape' || picturesData[index].status.slug==='a_hot',
                            'text-white' :picturesData[index].status.slug==='new'|| picturesData[index].status.slug.startsWith('r')
                        }"
                        >
                          <!-- For fetured picture write FEATURED instead FACE, for other - the STATUS -->
                          {{ picturesData[index].isFeatured ? trans('LABEL:FeaturedPicture'): trans(picturesData[index].status.name) }}
                        </span>
                      </div>

                      <!--      ZOOM ICON      -->
                      <div class="opacity-0 group-hover:opacity-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer">
                        <base-icon bigger-view :width="24" :height="24" name="zoom">
                        </base-icon>
                      </div>

                    </div>
                  </a>
                </span>

                <!--     PROFILE PICTURE BUTTON       -->
                <span class="hidden lg:block group-hover:hidden absolute top-3 right-3 cursor-pointer flex flex-row gap-2"
                    v-if="picturesData[index] && picturesData[index].hasOwnProperty('status') && picturesData[index].status.slug === 'a_face'"
                    @click="setProfilePicAction(picturesData[index].id)"
                >
                  <template v-if="picturesData[index].isFeatured">
                    <base-icon
                      bigger-view
                      :width="`${getPictureStatusIconSize(picturesData[index].status.slug, true)}`"
                      :height="`${getPictureStatusIconSize(picturesData[index].status.slug, true)}`"
                      class="opacity-100 -mt-1 -mr-1"
                      :name="`${getPictureStatusIcon(picturesData[index].status.slug, true)}`"
                    ></base-icon>
                  </template>
                  <template v-else>
                    <base-icon
                      bigger-view
                      :width="`${getPictureStatusIconSize(picturesData[index].status.slug, false)}`"
                      :height="`${getPictureStatusIconSize(picturesData[index].status.slug, false)}`"
                      class="opacity-70"
                      :name="`${getPictureStatusIcon(picturesData[index].status.slug, false)}`"
                    ></base-icon>
                  </template>
                </span>

                <span class="lg:hidden group-hover:block absolute top-3 right-3 cursor-pointer flex flex-row gap-3"
                      v-if="picturesData[index] && picturesData[index].hasOwnProperty('status')
                      && picturesData[index].status.slug === 'a_face'"
                      @click="setProfilePicAction(picturesData[index].id)">
                    <VTooltip v-show="picturesData[index].isFeatured">
                      <base-icon :width="32" :height="32"
	                        class="opacity-70 hover:opacity-100"
	                        name="profilePictureFilled"
                      ></base-icon>
                      <template #popper>
                        {{ trans('TOOLTIP:UnsetFeaturedPicture') }}
                      </template>
                    </VTooltip>
                    <VTooltip v-show="!picturesData[index].isFeatured">
                      <base-icon :width="40" :height="40"
                          class="opacity-70 hover:opacity-100 -mt-1 -mr-1"
                          name="profilePictureTickedFilledColored"
                      ></base-icon>
                      <template #popper>
                        {{ trans('TOOLTIP:SetFeaturedPicture') }}
                      </template>
                    </VTooltip>
                </span>

              <!--      DELETE BUTTON      -->
                <span class="lg:hidden group-hover:block absolute bottom-3 right-3 cursor-pointer"
                      @click="deleteFile(fileRecord.raw, fileRecord)">
                  <VTooltip>
                    <base-icon :width="24" :height="24" color="fill-white" name="trash" class="opacity-70 hover:opacity-100"></base-icon>
                      <template #popper>
                        {{ trans('TOOLTIP:DeletePicture') }}
                      </template>
                  </VTooltip>
                </span>

              <!--      ERROR MESSAGE     -->
                <span class="file-error-wrapper" v-if="fileRecord && fileRecord.error">
                  <span class="file-error-message file-error-message-client">
                    <template v-if="fileRecord.error">
                      {{ trans('ALERT:PictureTooBigOrWrongType') }}
                    </template>
                  </span>
                </span>

              <!--      LOADING STATE     -->
	            <div class="relative top-20"
	            >
	              <Loader bg-transparent v-if="fileRecord.hasProgress() && fileRecord.progress() > 0 && fileRecord.progress() < 100" />
	            </div>
              </span>
          </div>
        </template>
        <template v-slot:file-preview-new>
          <div id="filePreviewNew"
            key="new"
            class="file-preview-wrapper grid-box-item grid-block"
          >
            <span class="w-modal-picture h-modal-picture">
              <img key="new" class="w-modal-picture h-modal-picture object-contain rounded-lg border border-dashed border-grayed"
                :src="unionIcon"
                alt="profile picture"
              />
            </span>
          </div>

        </template>
      </VueFileAgent>
    </div>
    <div v-if="galleryData && galleryData.length > 0"
      class="flex flex-row justify-end"
    >
      <Button
        variant="link"
        :text="trans('BUTTON:ClearGallery')"
        class="text-s inline-flex pr-0 hover:opacity-50 cursor-pointer items-center"
        @clicked="openClearGalleryModal(true)"
      >
        <base-icon class="mr-2" name="reset">
        </base-icon>
      </Button>
    </div>
    <Modal
      :showModal="isShowGalleryDropzone"
      :buttonConfirmText="trans('BUTTON:Yes')"
      @onConfirm="clearGallery"
      :loading="galleryDeleting"
      @clicked="openClearGalleryModal(false)"
      :header="trans('MODAL_TITLE:Attention')"
    >
      <template v-slot:content>
        {{ trans('MODAL_DESCRIPTION:ClearGallery') }}
      </template>
    </Modal>
    <div class="absolute lg:-right-96 right-5 top-2" style="z-index: 9999; " v-if="galleryIndex !== null">
      <Button
        variant="icon"
        @clicked="galleryIndex = null"
      >
        <base-icon :width="24" :height="24" name="x" color="fill-white">

        </base-icon>
      </Button>
    </div>
  </div>
</template>

<script>

import AddPictureButton from "@/views/content/profile/components/gallery/components/AddPictureButton";
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import ImageModal from "@/utils/modal/ImageModal";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal";
import config from "@/config";
import store from "@/store";
import themeConfig from "@/themeConfig";
import {
  getPictureFilter,
  getPictureStatusIcon,
  getPictureStatusIconMargins,
  getPictureStatusIconSize,
} from "@/common/helpers/utils";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "GalleryEdition",
  components: {
    AddPictureButton,
    Badge,
    BadgeFrame,
    BaseIcon,
    Button,
    ImageModal,
    Loader,
    Modal,
  },
  props: {
    galleryData: [Array, Object],
    isUserOwner: Boolean,
  },
  data() {
    return {
      galleryIndex: null,
      galleryLightbox: [],
      getPictureFilter: getPictureFilter,
      getPictureStatusIcon: getPictureStatusIcon,
      getPictureStatusIconMargins:getPictureStatusIconMargins,
      getPictureStatusIconSize: getPictureStatusIconSize,
      isShowClearGalleryModal: false,
      isShowGalleryDropzone: false,
      isShowImageModal: false,
      openedImage: null,
      options: null,
      pictureLoaderSet: false,
      picturesData: [],
      render: false,
      unionIcon: themeConfig.icons.unionGallery,
	    imagesContainerSearchBy: '[helperclass="active-sorting-item"]',
	    disableUpperLoading: false,
    }
  },
  watch: {
    galleryData: {
      immediate: true,
      deep: true,
      handler(data) {
        this.picturesData = data;
        this.galleryLightbox = [];
        if(data && data.length > 0) {
          data.filter(el => {
            this.galleryLightbox.push(typeof(el.url) === 'function' ? el.url() : el.url)
          });
        }
      },
    },
    galleryDeleted(val) {
      if (val) {
        this.openClearGalleryModal(false);
      }
    },
	  picturesLoadingCounter(val) {
			if(val === 0) {
				this.setImagesContainerDisplay('block');
				this.disableUpperLoading = true;
			}
	  }
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('lang', ['configData']),
    ...mapGetters('gallery', ['galleryDeleting', 'galleryDeleted', 'pictureDeleted']),
	  ...mapGetters('utils', ['picturesLoadingCounter']),
  },
  methods: {
    ...mapActions('gallery', ['deletePhoto', 'fetchUserGallery', 'clearGalleryRequest', 'setProfilePictureRequest']),
    ...mapActions('alert', ['error', 'success']),
    ...mapMutations('profile', ['setPicturesPending']),
    ...mapMutations('gallery', ['setGalleryData', 'addToGallery']),
	  ...mapMutations('utils', ['setPicturesLoadingCounter']),

    deleteFile(e, fileRecord) {
      if (e.hasOwnProperty('id')) {
        this.deletePhoto({pictureId: e.id})
        this.$refs.fileUploader.removeFileRecord(fileRecord)
      } else {
        this.$refs.fileUploader.removeFileRecord(fileRecord)
      }
    },

    onUpload(responses) {
      let response;
      for (response of responses) {
        if (response.error) {
          continue;
        }
        this.addToGallery(response.data.data.picture);
      }
      this.setPicturesPending(responses.length);
    },

    clearGallery() {
      this.clearGalleryRequest();
	    document.getElementById('filePreviewNew').style.display = 'block';
    },

    openClearGalleryModal(value) {
      this.isShowGalleryDropzone = value;
    },

    openImageModal(value, src = null) {
      this.isShowImageModal = value;
      this.openedImage = value ? src : null;
    },

    openLightBoxGallery(index) {
      this.$emit('open-lightbox', index);
    },

    setProfilePicAction(pictureId) {
      this.setProfilePictureRequest({picture_id: pictureId})
	    this.$emit('profile-picture-set');
    },

	  setImagesContainerDisplay(display) {
		  let items = document.querySelectorAll(this.imagesContainerSearchBy);
		  for (const el of items) {
			  el.style.display = display;
		  }
	  }
  },
  beforeMount() {
    this.options = {
      headers: {
        'Authorization': `Bearer ${store.getters['account/accessToken']}`
      },
      url: config.API_URL + '/pictures',
      maxSize: this.configData.maxImageSize
    };
  },
  mounted() {
	  this.$refs.fileUploader.$children[1].$el.children[0].classList = ['flex flex-wrap gap-1.5 lg:gap-3'];
	  if (this.picturesData.length > 0 || this.picturesLoadingCounter !== 0) {
		  this.setPicturesLoadingCounter(this.picturesData.length);
	  }
		if(this.picturesData.length > 0) {
			this.setImagesContainerDisplay('none');
		}

		if(this.picturesData.length === 0) {
			document.getElementById('filePreviewNew').style.display = 'block';
		}
  },
}
</script>

<style lang="scss">

.grid-box-enter,
.grid-box-leave-to {
  opacity: 1 !important;
  /*transform: translate3d(-20px, -20px, -20px) scale(0) !important;*/
  transform: none !important;
}

.grid-box-leave-active {
  opacity: 1;
  position: absolute !important;
}

.grid-box-item{
  transition-duration: 0ms;

}

</style>
