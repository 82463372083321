<template>
  <div class="flex-row space-x-2 items-center" :class="[classes]" @click="$emit('clicked')">
    <base-icon :name="iconName"></base-icon>
    <span>
      {{ text }}
    </span>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";

export default {
  name: "IconLabel",
  components: {BaseIcon},
  props: {
    text: String,
    iconName: String,
    classes: String,
  }
}
</script>
