<template>
  <t-modal
      :variant="variant"
      ref="modal"
      v-model="showModalDialog"
  >
    <slot>
      <div>
        <Picture classes="object-contain rounded block"
             style="max-height: calc(100vh - 140px);"
             :src="img.url" alt=""
             :class="{
               'w-full lg:max-w-modal-gallery': horizontal,
               'w-full object-cover lg:min-w-modal-gallery-vertical': vertical
             }"
        />
      </div>
    </slot>
  </t-modal>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";

export default {
  name: "ImageModal",
  components: {BaseIcon, Button},
  props: {
    loading: Boolean,
    disabled: Boolean,
    buttonConfirmText: String,
    showModal: Boolean,
    confirmDisabled: Boolean,
    showCancelButton: {
      default: true,
      type: Boolean,
    },
    variant: String,
    header: String,
    buttonCancelText: String,
    img: [Object],
  },
  data() {
    return {
      vertical: false,
      horizontal: false,
    }
  },
  computed: {
    showModalDialog: {
      get: function () {
        return this.showModal;
      },
      set: function (value) {
        if (value === false) {
          this.$emit('clicked');
        }
        return value;
      }
    }
  },
  methods: {
    confirmAction() {
      this.$emit('onConfirm');
    },
    cancelAction() {
      this.showModalDialog = false;
    }
  },
  mounted() {
    if(this.img.width > this.img.height) {
      this.horizontal = true;
    } else {
      this.vertical = true;
    }
  },
  setup() {
    return {

    }
  }
}
</script>
