<template>
  <div class="flex flex-row justify-between"
   :class="{
      'cursor-pointer': this.count>0  && !this.isFavourite,
      'cursor-default': this.count===0 || this.isFavourite,
      'opacity-100': this.count>0,
      'opacity-50': this.count===0,
   }"
    @click="$emit('clicked')"
  >
    <div class="inline-flex items-center gap-3">
      <slot></slot>
      <span class="font-semibold text-s">
        {{ text }}
        <template v-if="!noCounters">
          <template v-if="total">
            ({{ `${count}/${total}` }})
          </template>
          <template v-else>
            ({{ `${count}` }})
          </template>
        </template>
      </span>
    </div>
    <div class="flex items-center">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "ListHeaderSection",
  props: {
    text: String,
    isFavourite:Boolean,
    total: [Number, String. null],
    count: [Number, null],
    noMargin: Boolean,
    noCounters: Boolean,
  }
}
</script>
