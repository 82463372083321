<template>
  <div v-if="userData" ref="filters" class="flex flex-col gap-2.5">
    <ListHeaderSection
        class="cursor-pointer"
        noMargin
        :text="trans('LABEL:Filters')"
        @clicked="setFiltersShown(!filtersShown)"
        no-counters
    >
      <base-icon :width="17" :height="16" name="filters">
      </base-icon>
      <template v-slot:action>
        <t-button variant="iconDropdown" @clicked="setFiltersShown(!filtersShown)">
          <base-icon v-if="filtersShown" name="chevronUp"></base-icon>
          <base-icon v-else name="chevronDown"></base-icon>
        </t-button>
      </template>
    </ListHeaderSection>
    <ul v-show="filtersShown">
      <transition-group class="flex flex-col gap-3 mt-3 lg:mt-4" name="slide">
        <li class="flex flex-row" :key="1">
          <div class="w-full">
            <SelectField
                v-model="filters.gender_combined_id"
                :options="genderData"
                slugValue
                :placeholder="trans('LABEL:Gender')"
            >
            </SelectField>
          </div>
        </li>
        <li class="flex flex-row gap-5 items-center" :key="2">
          <div class="w-1/2 custom-slider">
            <MultiRangeSlider
                :labels="['18','21','25','30','35','40','45','50','55','60','65','70','75','80','80+']"
                :minValue="ageFilterIndex(filters.age_min,18)"
                :maxValue="ageFilterIndex(filters.age_max,1000)"
                @rangeStop="ageFilterUpdate"
            />
          </div>
          <div class="flex items-center w-1/2">
            <FakeTextField
              class="w-full justify-end pr-0 text-base"
              :text="(filters.age_min?filters.age_min:'18')+' - '+(filters.age_max&&filters.age_max<1000?filters.age_max:'80+')+' '+trans('LABEL:years')"
            />
          </div>
        </li>
        <li class="flex flex-row space-x-5 items-center" :key="3">
          <div class="w-1/2 custom-slider">
            <MultiRangeSlider
              :labels="['0','140','145','150','155','160','165','170','175','180','185','190','195','200','200+']"
              :minValue="heightFilterIndex(filters.height_min,0)"
              :maxValue="heightFilterIndex(filters.height_max,1000)"
              @rangeStop="heightFilterUpdate"
            />
          </div>
          <div class="flex items-center w-1/2">
            <FakeTextField
                class="w-full justify-end pr-0"
                :text="(filters.height_min?filters.height_min:'0')+' - '+(filters.height_max&&filters.height_max<1000?filters.height_max:'200+')+' '+trans('cm')"
            />
          </div>
        </li>
        <li class="flex flex-row space-x-5 items-center" :key="4">
          <div class="w-1/2 custom-slider">
            <MultiRangeSlider
              :labels="['0','40','45','50','55','60','65','70','75','80','85','90','100','110','120','130','140','150','150+']"
              :minValue="weightFilterIndex(filters.weight_min,0)"
              :maxValue="weightFilterIndex(filters.weight_max,1000)"
              @rangeStop="weightFilterUpdate"
            />
          </div>
          <div class="flex items-center w-1/2">
            <FakeTextField
              class="w-full justify-end pr-0"
              :text="(filters.weight_min?filters.weight_min:'0')+' - '+(filters.weight_max&&filters.weight_max<1000?filters.weight_max:'150+')+' '+trans('kg')"
            />
          </div>
        </li>
        <li class="flex flex-row" :key="5">
          <div class="w-full">
            <SelectField
                v-model="filters.shape"
                :options="shapeData"
                slugValue
                text-attr="nameBoth"
                :placeholder="trans('LABEL:Shape')"
            >
            </SelectField>
          </div>
        </li>
        <li class="flex flex-row" :key="6">
          <div class="w-full">
            <SelectField
                v-model="filters.orientation"
                :options="orientationData"
                slugValue
                :placeholder="trans('LABEL:Orientation')"
            >
            </SelectField>
          </div>
        </li>
        <li class="flex flex-row" :key="7">
          <div class="w-full">
            <SelectField
                v-model="filters.preference"
                :options="preferenceData"
                slugValue
                text-attr="nameBoth"
                :placeholder="trans('LABEL:Preference')"
            >
            </SelectField>
          </div>
        </li>
        <li class="flex flex-row" :key="8">
          <div class="w-full">
            <SelectField
                v-model="filters.search_whos"
                :options="searchWhosData"
                slugValue
                :placeholder="trans('LABEL:LookingFor', 'both')"
            >
            </SelectField>
          </div>
        </li>
        <li class="flex flex-row" :key="9">
          <div class="w-full">
            <SelectField
                v-model="filters.search_whats"
                :options="searchWhatsData"
                slugValue
                :placeholder="trans('LABEL:InterestedIn', 'both')"
            >
            </SelectField>
          </div>
        </li>
        <li class="flex flex-row space-x-5 items-center" :key="10" v-if="userData.location.id">
          <div class="w-1/2 custom-slider">
            <MultiRangeSlider
                :labels="['0','10','25','50','100','250','500','1000','1000+']"
                :minValue="distanceFilterIndex(filters.distance_min,0)"
                :maxValue="distanceFilterIndex(filters.distance_max,100000)"
                @rangeStop="distanceFilterUpdate"
            />
          </div>
          <div class="flex items-center w-1/2">
            <FakeTextField
              class="w-full justify-end pr-0"
              :text="(filters.distance_min?filters.distance_min:'0')+' - '+(filters.distance_max&&filters.distance_max<100000?filters.distance_max:'1000+')+' '+trans('km')"
            />
          </div>
        </li>
        <li class="flex flex-row items-center justify-between" :key="11">
          <Button
              variant="textWithIcon"
              class="text-s"
              icon="reset"
              :icon-height="16"
              :icon-width="16"
              :disabled="clearButtonDisabled"
              :text="trans('BUTTON:Reset')"
              @clicked="clearFilters"
          >
          </Button>
          <template v-if="favFilterExistName !== null">
            <VDropdown class="cursor-pointer hover:opacity-50" placement="top">
              <Button
                variant="buttonRed"
                :text="trans('BUTTON:FavouriteSearchSave')"
                :disabled="userData.favouriteFilters.length >= configData.userFiltersLimit || saveButtonDisabled"
              >
              </Button>
              <template #popper>
                {{ trans('TOOLTIP:FiltersAlreadySetWithName') + '"' + favFilterExistName + '"'}}
              </template>
            </VDropdown>
          </template>
          <template v-else>
            <Button
              variant="buttonRed"
              :text="trans('BUTTON:FavouriteSearchSave')"
              :disabled="userData.favouriteFilters.length >= configData.userFiltersLimit || saveButtonDisabled"
              @clicked="openFilterNameModal(true)"
            >
            </Button>
          </template>
        </li>
      </transition-group>
      <ValidationObserver ref="filterNameForm" v-slot="{ invalid }">
        <Modal
            v-if="showFilterNameModal"
            :show-modal="showFilterNameModal"
            @clicked="openFilterNameModal(false)"
            @onConfirm="saveFilters"
            :confirm-disabled="invalid"
            :loading="favFiltersLoading"
            :header="trans('MODAL_TITLE:FilterName')"
        >
          <template v-slot:content>
            <TextField
                v-model="filterName"
                :placeholder="trans('LABEL:FilterName')"
                rules="required|min:2|max:30"
            >
            </TextField>
          </template>
        </Modal>
      </ValidationObserver>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import IconLabel from "@/utils/IconLabel";
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import SelectField from "@/utils/crud/components/field-types/Select";
import MultiRangeSlider from "@/plugins/MultiRangeSlider";
import TextField from "@/utils/crud/components/field-types/Text";
import FakeTextField from "@/utils/crud/components/field-types/FakeTextField";
import Modal from "@/utils/modal/Modal";
import {areObjectsEqual, differenceObjects, isEmpty} from "@/common/helpers/utils";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import isEqual from 'lodash/isEqual';
import ListHeaderSection from "@/views/content/messages/components/ListHeaderSection";
import {difference, differenceBy, differenceWith} from "lodash/array";
import {pickBy} from "lodash/object";

const initialFilters = () => {
  return {
    age_min: 18,
    age_max: 1000,
    height_min: 0,
    height_max: 1000,
    weight_min: 0,
    weight_max: 1000,
	  gender_combined_id: '',
    orientation: '',
    preference: '',
    search_whats: '',
    search_whos: '',
    shape: '',
    distance_min: 0,
    distance_max: 100000,
  }
}

export default {
  name: "Filters",
  components: {
    ListHeaderSection,
    Modal, FakeTextField, TextField, MultiRangeSlider, SelectField, Button, BaseIcon, IconLabel},
  data() {
    return {
      areFiltersShown: true,
      ageMin: initialFilters().age_min,
      ageMax: initialFilters().age_max,
      heightMin: initialFilters().height_min,
      heightMax: initialFilters().height_max,
      weightMin: initialFilters().weight_min,
      weightMax: initialFilters().weight_max,
      distanceMin: initialFilters().distance_min,
      distanceMax: initialFilters().distance_max,
      filters: initialFilters(),
      showFilterNameModal: false,
      filterName: null,
      clearButtonDisabled: true,
      saveButtonDisabled: true,
      favFilterExistName: null,
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['genderData', 'shapeData', 'orientationData', 'preferenceData', 'searchWhosData', 'searchWhatsData']),
    ...mapGetters('lang', ['langLocale', 'configData']),
    ...mapGetters('users', [
        'favFiltersLoading', 'favFilters', 'isInitialSearch',
      'favFiltersLoading', 'savedFilters', 'userFilters',
      'activeFilter', 'isShowEditFilterNameModal', 'filterToEdit', 'updatedFilter'
    ]),
    ...mapGetters('account', ['userData']),
    ...mapGetters('favourites', ['favouriteUsersData', 'favUsersLoading']),
	  ...mapGetters('utils', ['filtersShown'])
  },
  watch: {
    langLocale() {
      this.fetchShapeData();
      this.fetchOrientationData();
      this.fetchPreferenceData();
      this.fetchSearchWhosData();
      this.fetchSearchWhatsData();
    },
    filters: {
      deep: true,
      handler(val) {
        let filters = {...val};
        if(filters.init_search) {
          delete filters.init_search;
        }
        let filtersHasDifferences = Object.keys(differenceObjects(initialFilters(), filters)).length < 3;
        this.setIsInitialSearch(filtersHasDifferences);
        this.setFilters(filters);
        this.clearButtonDisabled = areObjectsEqual(initialFilters(), filters);
        this.saveButtonDisabled = filtersHasDifferences;
        let favFilter = this.userData.favouriteFilters.find(el => isEqual(fromQueryToObject(el.query), filters));
        this.favFilterExistName = favFilter?.name ?? null;
        if(this.favFilterExistName !== null) {
          this.setActiveFilter(favFilter.id);
        } else {
          this.unsetActiveFilter();
        }
      }
    },
    favFilters(val) {
      this.filters = structuredClone(val);
    },
    savedFilters(val) {
      if(val) {
        this.filterName = null;
        this.favFilterExistName = this.userData.favouriteFilters.find(el => el.id === this.activeFilter).name;
        this.openFilterNameModal(false);
      }
    },
    updatedFilter(val) {
      if(val) {
        this.filterName = null;
        this.openFilterNameModal(false);
        this.setFilterToEdit(null);
      }
    },
    filterToEdit(val) {
      if(val) {
        this.filterName = this.userData.favouriteFilters.find(el => el.id === val).name
        this.openFilterNameModal(true);
      }
    },
	  'userData.favouriteFilters': {
			deep: true,
		  handler(val) {
				if(!val.find(el => el.name === this.favFilterExistName)) {
					this.favFilterExistName = null;
				}
		  }
	  }
  },
  methods: {
    ...mapActions('dictionaries', ['fetchShapeData', 'fetchOrientationData', 'fetchPreferenceData', 'fetchSearchWhosData', 'fetchSearchWhatsData']),
    ...mapActions('users', ['fetchUsersData', 'saveFavFiltersRequest']),
    ...mapActions('favourites', ['fetchFavouriteUsers']),
    ...mapMutations('users', ['setFilters', 'setFavFilters', 'clearFilters', 'unsetActiveFilter', 'unsetFavouriteFilters', 'setActiveFilter', 'setFilterToEdit', 'setIsInitialSearch', 'setLastPage']),
	  ...mapMutations('utils', ['setFiltersShown']),

    ageFilterIndex(val,def) {
      const labels=[18,21,25,30,35,40,45,50,55,60,65,70,75,80,1000];
      const isElementEqualToVal = (element) => element === val;
      return val?labels.findIndex(isElementEqualToVal):def;
    },
    ageFilterUpdate(e) {
      const labels=[18,21,25,30,35,40,45,50,55,60,65,70,75,80,1000];
      this.filters.age_min = labels[e.minValue];
      this.filters.age_max = labels[e.maxValue];
    },

    clearFilters() {
      this.filters = initialFilters();
      this.unsetActiveFilter();
      this.setLocalFilters();
      this.setIsInitialSearch(true);
    },
    
    distanceFilterIndex(val,def) {
      const labels=[0,10,25,50,100,250,500,1000,100000];
      const isElementEqualToVal = (element) => element === val;
      return val?labels.findIndex(isElementEqualToVal):def;
    },
    distanceFilterUpdate(e) {
      const labels=[0,10,25,50,100,250,500,1000,100000];
      this.filters.distance_min = labels[e.minValue];
      this.filters.distance_max = labels[e.maxValue];
    },

    heightFilterIndex(val,def) {
      const labels=[0,140,145,150,155,160,165,170,175,180,185,190,195,200,1000];
      const isElementEqualToVal = (element) => element === val;
      return val?labels.findIndex(isElementEqualToVal):def;
    },
    heightFilterUpdate(e) {
      const labels=[0,140,145,150,155,160,165,170,175,180,185,190,195,200,1000];
      this.filters.height_min = labels[e.minValue];
      this.filters.height_max = labels[e.maxValue];
    },
    
    openFilterNameModal(value) {
      if(!value) {
        this.setFilterToEdit(null);
        this.filterName = null;
      }
      this.showFilterNameModal = value;
    },

    saveFilters() {
      let data = {
        filterName: this.filterName
      };
      if(this.filterToEdit !== null) {
        data.id = this.filterToEdit;
      }
      this.saveFavFiltersRequest(data);
    },

    setLocalFilters(val) {
      if(val) {
        localStorage.setItem('filters', val);
      } else {
        localStorage.removeItem('filters');
      }
    },

    weightFilterIndex(val,def) {
      const labels=[0,40,45,50,55,60,65,70,75,80,85,90,100,110,120,130,140,150,1000];
      const isElementEqualToVal = (element) => element === val;
      return val?labels.findIndex(isElementEqualToVal):def;
    },
    weightFilterUpdate(e) {
      const labels=[0,40,45,50,55,60,65,70,75,80,85,90,100,110,120,130,140,150,1000];
      this.filters.weight_min = labels[e.minValue];
      this.filters.weight_max = labels[e.maxValue];
    },

  },
  mounted() {
    this.filters.age_min = this.ageMin;
    this.filters.age_max = this.ageMax;
    this.filters.height_min = this.heightMin;
    this.filters.height_max = this.heightMax;
    this.filters.weight_min = this.weightMin;
    this.filters.weight_max = this.weightMax;
    this.filters.distance_min = this.distanceMin;
    this.filters.distance_max = this.distanceMax;

    this.fetchShapeData();
    this.fetchOrientationData();
    this.fetchPreferenceData();
    this.fetchSearchWhosData();
    this.fetchSearchWhatsData();

		if(localStorage.getItem('filters') && !areObjectsEqual(initialFilters(), JSON.parse(localStorage.getItem('filters')))) {
			this.filters = JSON.parse(localStorage.getItem('filters'))
		} else {
      this.fetchUsersData(this.filters);
    }

  },
  beforeMount() {

  },
  beforeDestroy() {
		this.setLocalFilters(JSON.stringify(this.filters));
  }

}
</script>
